import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { FooterAlternate1 } from "../../components/footer"
import SEO from "../../components/seo"

const IndexPage = ({ location }) => { 
    const [playing, setPlaying] = useState(false);

return (
  <Layout location={location}>
    <SEO title="Companies" />

    <header className="header header--basic ">
      <div className="header__content">
          <div className="container">
              <div className="row">
                  <div className="header__inner col-sm-6 col-lg-5 col-lg-offset-1">
                      <Link to="/how-it-works/" className="header__back back-link">
                          <span className="sr-only">Back</span>
                          <i className="icon"></i>
                      </Link>
                      <h3 className="header__category">How it works</h3>
                      <h1 className="header__title">Companies</h1>
                      <h3 className="header__caption">....need help with sales enablement to adapt to the modern buyer.</h3>
                  </div>
              </div>
          </div>
      </div>
    </header>
    <div className="landing landing--narrative">
        <div className="landing__item">
            <div className="landing__svg container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1133 715" width="1133" height="715" preserveAspectRatio="xMinYMin">
                    <defs>
                        <clipPath id="landingMaskA">
                            <path d="M0 0h1133v307H0z" />
                        </clipPath>
                    </defs>
                    <g className="hidden-xs" clipPath="url(#landingMaskA)">
                        <path fill="none" stroke="#f9f9fa" strokeWidth="10" strokeMiterlimit="10" d="M566 12h114a50 50 0 0 1 50 43v660" />
                        <path className="green" fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M566 12h114a50 50 0 0 1 50 43v660" />
                    </g>
                    <path fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M682 120h451" className="visible-xs" />
                </svg>
            </div>
            <div className="container">
                <div className="row">
                    <div className="landing__columns">
                        <div className="col-xs-12 col-sm-6 ">
                            <div className="landing__image">
                                <img src="/media/svg/landing-256x183_shuttle.svg" alt="" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-4 col-md-offset-1">
                            <div className="landing__copy">
                                <h2 className="landing__title">Does your business need to accelerate revenue growth?</h2>
                                <p>SalesTribe delivers worlds best practices around sales talent, process and technology</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="landing__item is-odd">
            <div className="landing__svg container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1133 850" width="1133" height="850" preserveAspectRatio="xMinYMin">
                    <defs>
                        <clipPath id="landingMaskB">
                            <path d="M0 0h1133v447H0z" />
                        </clipPath>
                    </defs>
                    <g className="hidden-xs" clipPath="url(#landingMaskB)">
                        <path fill="none" stroke="#f9f9fa" strokeWidth="10" strokeMiterlimit="10" d="M730 0v250a50 50 0 0 1-50 50H240a50 50 0 0 0-50 50v500" />
                        <path className="green" fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M730 0v250a50 50 0 0 1-50 50H240a50 50 0 0 0-50 50v500" />
                    </g>
                    <path fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M1133 12H591a25 25 0 0 0-25 25v28" className="visible-xs" />
                </svg>
            </div>
            <div className="container">
                <div className="row">
                    <div className="landing__columns">
                        <div className="col-xs-12 col-sm-6 col-sm-push-6">
                            <div className="landing__image">
                                <img src="/media/svg/landing-256x183_brand.svg" alt="" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-4 col-sm-pull-5">
                            <div className="landing__copy">
                                <h2 className="landing__title">Need to adapt your sales process to the new buyer journey?</h2>
                                <p>SalesTribe&#39;s 8 Step Process will help you accelerate sales growth</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="landing__item">
            <div className="landing__svg container">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1133 850" width="1133" height="850" preserveAspectRatio="xMinYMin">
                    <defs>
                        <clipPath id="landingMaskB">
                            <path d="M0 0h1133v447H0z" />
                        </clipPath>
                        <clipPath id="landingMaskBMobile">
                            <path d="M0 0h1133v60H0zM683 180h450v20H683z" />
                        </clipPath>
                    </defs>
                    <g className="hidden-xs" clipPath="url(#landingMaskB)">
                        <path fill="none" stroke="#f9f9fa" strokeWidth="10" strokeMiterlimit="10" d="M190 0v250a50 50 0 0 0 50 50h440a50 50 0 0 1 50 50v500" />
                        <path className="green" fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M190 0v250a50 50 0 0 0 50 50h440a50 50 0 0 1 50 50v500" />
                    </g>
                    <g className="visible-xs" clipPath="url(#landingMaskBMobile)">
                        <path fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M566 0v190h567" />
                    </g>
                </svg>

            </div>
            <div className="container">
                <div className="row">
                    <div className="landing__columns">
                        <div className="col-xs-12 col-sm-6 ">
                            <div className="landing__image">
                                <img src="/media/svg/landing-256x183_people.svg" alt="" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-4 col-md-offset-1">
                            <div className="landing__copy">
                                <h2 className="landing__title">Have you got the right-fit sales talent?</h2>
                                <p>SalesTribe is your specialist sales talent partner - our &#39;Tribe&#39; is Screened, Approved, Trained and Certified</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="landing__item is-end-cap">
            <div className="landing__svg container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1133 250" width="1133" height="250" preserveAspectRatio="xMinYMin">
                    <g className="hidden-xs">
                        <path fill="none" stroke="#f9f9fa" strokeWidth="10" strokeMiterlimit="10" d="M730 0v250" />
                        <path className="green" fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M730 0v250" />
                    </g>
                    <path fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M566 0v250" className="visible-xs" />
                </svg>
            </div>
        </div>

        <svg style={{height:0, width:0}} xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="landingMaskA">
                    <path d="M0 0h1133v307H0z" />
                </clipPath>
                <clipPath id="landingMaskB">
                    <path d="M0 0h1133v447H0z" />
                </clipPath>
                <clipPath id="landingMaskBMobile">
                    <path d="M0 0h1133v60H0zM683 180h450v20H683z" />
                </clipPath>
            </defs>
        </svg>
    </div>

        <div className={`block block--base video ${playing ? "is-playing" : ""}`} id="info-video">
            <div className="video__wrap" onClick={() => setPlaying(true)}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="video__image" data-video-uri="https://www.youtube.com/embed/pu8aRi8L-ks?autoplay=1&amp;modestbranding=0&amp;rel=0&amp;showinfo=0" data-video-target="#info-video .embed-responsive" data-video-parent="#info-video">
                            <div className="video__bgimage" style={{backgroundImage:"url(/media/images/Mentor-Large-Graham-Hawkins.2e16d0ba.fill-1162x655.jpg)"}}></div>
                            <div className="video__video">
                                <div className="embed-responsive embed-responsive-16by9">
            { playing  &&<iframe width="560" height="315" src="https://www.youtube.com/embed/pu8aRi8L-ks?autoplay=1&amp;modestbranding=0&amp;rel=0&amp;showinfo=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>}
                                </div>
                            </div>
                        </div>
                        <div className="video__icon play-icon">
                            <i className="icon"></i>
                        </div>
                        <div className="video__content">
                            <div className="row">
                                <div className="video__inner col-md-offset-2 col-md-8">
                                    <p className="h3"></p>
                                    <h1>Modern Selling with Digital & Social</h1>
                                    <div className="video__caption">
                                        <p className="h3"></p>
                                        <p>Graham Hawkins - SalesTribe Mentor</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="block  banner">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-offset-1 col-md-10">

                    <h2 className="banner__title">Do you need help with sales?</h2>
                    <div className="banner__buttons">
                        <Link to="/enquiries/" className="btn btn-default">Contact us</Link>
                    </div>

                </div>
            </div>
        </div>
    </div>
      

  </Layout>
)}

export default IndexPage
